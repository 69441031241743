















import { mdiPlus } from "@mdi/js"

import PipelinesPage from "@/components/PipelinesPage.vue"
import { useUser } from "@/store/user"

export default defineComponent({
  components: { PipelinesPage },
  setup(_, { root }) {
    const user = useUser()

    watchEffect(() => {
      if (!user.isConnected) root.$router.replace("/login")
    })
    return { mdiPlus }
  },
})
